<template>
    <div class="main-wrapper">
        <el-scrollbar class="table-content">
            <div class="top-tab">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="customBreadcrumb">
                    <el-breadcrumb-item>实操训练</el-breadcrumb-item>
                    <el-breadcrumb-item>答题详情</el-breadcrumb-item>
                    <el-breadcrumb-item>查看详情</el-breadcrumb-item>
                </el-breadcrumb>
                <el-button type="primary" @click="toBack" class="go-back">返回</el-button>
            </div>
            <el-table :data="cyberInfo" class="customTable"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="title" label="考试内容"></el-table-column>
                <el-table-column label="学生提交答案" align="left">
                    <template slot-scope="scope">
                        <div class="link-box">
                            <el-link type="success" :underline="false" @click="viewRow(scope.row)" class="view-detail">
                                查看详情
                            </el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-scrollbar>
    </div>
</template>

<script>
    import pagination from "@/components/pagination";
    import {drill_answer,seeDrillStuDetail} from "@/utils/apis"

    export default {
        name: "cyberCustomerDetail",
        components: {
            pagination
        },
        data() {
            return {
                examId: this.$route.query.examId,
                studentId: this.$route.query.id,
                type: this.$route.query.type || null,
                scoreDetailDialog: false,
                scoreList: [],
                answerList: [],
                total: 0,
                currentPageNum: 1,
                cyberInfo: [],
            }
        },
        created() {
            this.getList();
        },
        methods: {
            toBack() {
                this.$router.go(-1)
            },
            getList() {
                if(this.$route.query.submit_id){
                    seeDrillStuDetail({submit_id: this.$route.query.submit_id}).then(res => {
                        this.cyberInfo = res.data.data
                    }).catch(err => {
                        console.error(err)
                    })
                } else {
                    let param = {
                        op_id: this.$route.query.opId,
                        course_id: this.$route.query.course_id,
                        chapter: this.$route.query.chapter,
                        node: this.$route.query.node,
                    }
                    drill_answer(param).then(res => {
                        this.cyberInfo = res.data.data
                    }).catch(err => {
                        console.error(err)
                    })
                }
            },
            viewRow(row) {
                if(this.$route.query.submit_id){
                    this.$router.push({
                        path: '/trainingCenter/practicalTraining/cyberCustomerService/ManagementDetail',
                        query: {
                            id: row.id,
                            type: 2,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/cyberCustomerService/ManagementDetail',
                        query: {
                            id: row.id,
                            type: 2,
                        }
                    })
                }
            },
            //切换分页
            handleCurrentChange(val) {
                this.currentPageNum = val;
                this.getScore();
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        padding: 0 20px 20px 20px;
        box-sizing: border-box;

        .table-content {
            height: 100%;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .customTable {
                width: 100%;
                flex: 1;
                margin-top: 20px;

                .show-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .link-box {
                    margin-left: 20px;
                }
            }

            .top-tab {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 2px solid #F1F5FF;

                .go-back {
                    margin-bottom: 12px;
                }
            }
        }
    }
</style>